import React from 'react';
import './App.css';
import axios from "axios";
import {BASE_URL} from "./Constants";
import LoadingSpinner from "./LoadingSpinner";
import moment from 'moment'

class ImportDetail extends React.Component {

    state = {
        isLoading: true,
        item: [],
        notFound: false
    }

    constructor(props) {
        super(props);

        this.showRegulatory = this.showRegulatory.bind(this);
        this.showChat = this.showChat.bind(this);
        this.showAcceptView = this.showAcceptView.bind(this);
    }

    componentDidMount() {

        this.loadData();
    }

    loadData() {
        const { id } = this.props.match.params

        axios.get(BASE_URL + "/import-request/details/" + id)
            .then(result => {
                const item = result.data.data;
                console.log(item);

                this.setState({
                    isLoading: false, item
                });
            })
            .catch(error => {
                console.log(error);
                if (error.response.status === 404) {
                    this.setState({ notFound: true, isLoading: false });
                }
            });
    }

    showRegulatory() {
        this.props.history.push("/regulatory");
    }

    showChat() {
        const { id } = this.props.match.params

        this.props.history.push("/importlist/" + id + "/ask");
    }

    showAcceptView() {
        const { id } = this.props.match.params

        this.props.history.push("/importlist/" + id + "/accept");
    }

    LoadingView() {
        return (
            <LoadingSpinner title="Laden..."/>
        );
    }

    Cell(label, value, onClick, icon, displayChevron) {
        return (
            <div className="importDetail-cell" onClick={ onClick }>
                <div className="importDetail-cell-left">
                    <div className="importDetail-cell-image" style={{ backgroundImage: "url('/" + icon + "')" }} />
                </div>

                <div className="importDetail-cell-right">
                    <p className="importDetail-cell-label" style={{ color: displayChevron ? "black" : "gray" }}>{ label }</p>
                </div>

                <div className="fill-remaining-space"/>

                <div className="importDetail-cell-right">
                    <p className="importDetail-cell-label" style={{ color: "black" }}>{ value }</p>

                    {
                        displayChevron ?
                            <div className="chevron" /> : null
                    }
                </div>
            </div>
        );
    }

    ProfileView() {
        return (
            <div className="profileBanner">
                <div className="profileBanner-left">
                    {
                        this.state.item.user.image !== null ?
                            <div className="profileBanner-image" style={{ backgroundImage: "url('" + this.state.item.user.image.url + "')" }} />
                            :
                            <div className="profileBanner-image" style={{ backgroundImage: "url('/user-solid.svg')", backgroundSize: "36px" }} />
                    }
                </div>

                <div className="profileBanner-right">
                    <p className="profileBanner-label" style={{ fontWeight: "700" }}>{ this.state.item.user.name }</p>
                    <p className="profileBanner-label" style={{ color: "gray" }}>{ this.state.item.user.address.zip } { this.state.item.user.address.city }</p>

                    <div className="profileBanner-starsContainer">
                        <div className="profileBanner-star" id={ this.state.item.user.ratingCount > 0 ? "fill" : null } />
                        <div className="profileBanner-star" id={ this.state.item.user.ratingCount > 1 ? "fill" : null } />
                        <div className="profileBanner-star" id={ this.state.item.user.ratingCount > 2 ? "fill" : null } />
                        <div className="profileBanner-star" id={ this.state.item.user.ratingCount > 3 ? "fill" : null } />
                        <div className="profileBanner-star" id={ this.state.item.user.ratingCount > 4 ? "fill" : null } />
                    </div>
                </div>
            </div>
        )
    }

    ImportDetail() {
        return (
            <div>
                {
                    this.state.item.images.length > 0 ?
                        <div className="importDetail-hero" style={{ backgroundImage: "url('" + this.state.item.images[0].url + "')" }} />
                        :
                        <div className="importDetail-hero" style={{ backgroundImage: "url('/image-regular.svg')", backgroundSize: "120px" }} />

                }
                <div className="spacer20" />

                <p className="heading-small">{ this.state.item.name }</p>
                <p className="heading-subtitle">{ this.state.item.category.name }</p>

                <div className="spacer20" />
                <div className="spacer20" />

                <div className="buttonArea" style={{display: "none"}}>
                    <div className="buttonArea-accessoryButton"
                         style={{ backgroundImage: "url('/questionmark.svg')" }}
                         onClick={ this.showChat }
                    />

                    <div className="buttonArea-executeButton"
                         onClick={ this.showAcceptView }
                    />

                    <div className="buttonArea-accessoryButton"
                         style={{ backgroundColor: "transparent" }}
                    />
                </div>

                <div className="spacer20" />
                <div className="spacer20" />

                {
                    this.Cell("Standort", this.state.item.location, null, "location.svg", false)
                }

                {
                    this.Cell("Preis", this.state.item.price_min + " - " + this.state.item.price_max  + " €", null, "currency.svg")
                }

                {
                    this.Cell("Benötigt bis", moment(this.state.item.date_to).format("DD.MM.YYYY"), null, "calendar.svg")
                }

                {
                    this.Cell("Reise- und Zollbestimmungen", "", this.showRegulatory, "doc.search.svg", true)
                }

                {
                    this.state.item.zahlungsempfaenger == null || this.state.item.zahlungsempfaenger == "" ? null :  this.Cell("Zahlungsempfänger", this.state.item.zahlungsempfaenger.replace("hotel", "Hotel").replace("customer", "Kunde / Empfänger"),  null,"doc.search.svg", false)
                }

                {
                    this.state.item.customer_email == null || this.state.item.customer_email == "" ? null : this.Cell("Kunden/Empfänger E-Mail-Adresse", this.state.item.customer_email,  null,"doc.search.svg", false)
                }

                <div className="spacer20" />
                <div className="spacer20" />

                <p className="bodyText">{ this.state.item.description }</p>

                { this.ProfileView() }

            </div>
        );
    }

    render() {
        return (
            <div className="wrapper">
                <div className="titlebar">
                    <p className="titlebar-title">Importdetails</p>
                </div>

                <div className="content">
                    {
                        this.state.isLoading ?
                            this.LoadingView()
                            :
                            this.state.notFound ?
                                "Dieser Importwunsch konnte nicht gefunden werden. Möglicherweise wurde er zwischenzeitlich gelöscht." :
                                this.ImportDetail()
                    }
                </div>
            </div>
        );
    }
}

export default ImportDetail;
