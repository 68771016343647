import React from 'react';
import {Link, useHistory} from "react-router-dom";
import './App.css';
import AuthManager from "./AuthManager";

function Footer() {
    let history = useHistory();

    function logout() {
        let authManager = new AuthManager();
        authManager.logout();

        history.push('/login');
    }

    return (
        <div className="footer">

            &copy; 2023 Spedition Mickeleit<br/>


        </div>
    );
}

export default Footer;
