import React from 'react';
import {Link} from "react-router-dom";
import ErrorBox from "./ErrorBox";
import Button from "./Button";
import axios from "axios";
import AuthManager from "./AuthManager";
import {BASE_URL, LOGIN_ENDPOINT} from "./Constants";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { withTranslation } from 'react-i18next';

class Login extends React.Component {

    state = {
        email: "",
        password: "",
        incorrectCredentials: false,
        errorMessage: null,
        showScanner: false,
        isSending: false,
        width: window.outerWidth,
        height: window.outerHeight,
        stopStream: false
    }

    constructor(props) {
        super(props);

        this.handleEmailFieldChange = this.handleEmailFieldChange.bind(this);
        this.handlePasswordFieldChange = this.handlePasswordFieldChange.bind(this);

        this.login = this.login.bind(this);
    }

    handleEmailFieldChange(event) {
        this.setState({email: event.target.value});
    }

    handlePasswordFieldChange(event) {
        this.setState({password: event.target.value});
    }

    login() {
        this.setState({ isSending: true });

        axios.post(BASE_URL + LOGIN_ENDPOINT, {
            transport_id: this.state.email
        })
            .then(result => {
                this.props.history.push('/ref/'+this.state.email);
            })
            .catch(error => {
                console.log(error)
                if (error.response) {
                    this.setState({ isSending: false, errorMessage: this.props.t('nothing_found') });
                }
            });
    }

    componentDidMount() {
        window.screen.orientation.addEventListener("change", function (e) {
            this.setState({
                stopStream: true,
                width: window.outerWidth,
                height: window.outerHeight,
            }, function() {
                this.setState({
                    stopStream: true,
                });
            })
        });
    }

    escapeRegExp(string) {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    replaceAll(str, find, replace) {
        return str.replace(new RegExp(this.escapeRegExp(find), 'g'), replace);
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    render() {

        return (
            <div className="wrapper">

                <div className="content">
                    <div style={{ width: 300, margin: "auto", textAlign: "center"}}>
                        <img src={'/logo.png'} style={{ width: 200, margin: "auto", textAlign: "center"}}/>
                    </div>

                    <p className="text" style={{marginTop: 30, marginBottom: 30}}>{this.props.t('scan_order')}</p>

                    <p className="bodyText" style={{ textAlign: "left", marginBottom: "15px", marginTop: "20px", fontWeight: "bold" }}>{this.props.t('referenznummer')}</p>
                    <div style={{display: "flex"}}>
                        <input type="text" placeholder={this.props.t('referenznummer')} value={ this.state.email } onChange={ this.handleEmailFieldChange } />
                        <img onClick={() => this.setState({showScanner: true})} src={"/barcode_code_scan_scanner_icon.png"} style={{cursor: "pointer", width: 40, height: 40, marginLeft: 10, marginTop: 5}}/>
                    </div>
                    {
                        this.state.showScanner ? <div style={{zIndex: 100, left: 0, top: 0, position: "absolute", width: this.state.width, height: this.state.height}}>
                            <p  onClick={() => this.setState({showScanner: false})} style={{cursor: "pointer", zIndex: 101, margin:0, padding: 10, borderRadius: 100, left: 20, top: 20, position: "absolute",fontSize: 20, backgroundColor: '#000', color: "#fff"}}>x</p>
                            <BarcodeScannerComponent
                                width={this.state.width}
                                height={this.state.height}
                                stopStream={this.state.stopStream}
                                delay={1000}
                                onUpdate={(err, result) => {
                                    if (result) {this.setState({showScanner: false, email: result.text}, function() {
                                        this.login()
                                    })}
                                }}
                            />
                        </div> : null
                    }

                    { this.ErrorsView() }

                    <Button title={this.props.t('search')}
                            onClick={ this.login }
                            showLoadingState={ this.state.isSending }
                            disabled={this.state.email.length === 0}
                    />

                </div>
            </div>
        );
    }

}

export default withTranslation()(Login);
