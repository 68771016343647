import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

var userLang = navigator.language || navigator.userLanguage;

const lang = [
    {"lng": "de-DE", "set": "de"},
    {"lng": "en-EN", "set": "en"},
    {"lng": "en-US", "set": "en"},
    {"lng": "ru", "set": "ru"},
    {"lng": "ru-RU", "set": "ru"},
    {"lng": "pl", "set": "pl"},
    {"lng": "pl-PL", "set": "pl"}
];

let current = lang.filter(x => x.lng == userLang);
let findlang= "en";
if(current.length > 0){
    findlang = current[0].set;
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

let x = getCookie("user_lang");

if(x.length == 0) {
    setCookie("user_lang", findlang, 356)
}else{
    findlang = x;
}

i18next.init({

    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
        en: {
            translation: {
                "scan_order": "Scan or type in order",
                "referenznummer": "Reference number",
                "search": "search",
                "nothing_found": "Reference number not found",
                "select_all": "select all",
                "all_right": "All error-free",
                "all_errors": "All faulty",
                "empfaenger": "Recipient",
                "artikel": "Article",
                "transport": "Transport",
                "error": "Faulty",
                "success": "Error free",
                "edit": "edit",
                "reset": "reset",
                "deviation": "Enter the deviation in the number of pallets and boxes",
                "ready": "ready",
                "number_pallets": "Number of pallets",
                "number_boxes": "Number of boxes",
                "delivery_file": "Please take a photo of the receipted delivery note and attach it",
                "required_file": "Delivery note required",
                "sendet": "sending",
                "take_image": "Please take a picture of the faulty product and attach it",
                "image_optional": "image optional",
                "error_sendet": "Please upload a confirmed delivery note.",
                "frachtauftrag": "Freight order",
                "close": "Complete",
                "done": "Almost done",
                "done_txt": "All items have been checked. Please confirm acceptance.",
                "cancel": "Cancel"
            }
        },
        pl: {
            translation: {
                "scan_order": "Zeskanuj lub wpisz w kolejności",
                "referenznummer": "Numer referencyjny",
                "search": "szukaj",
                "nothing_found": "Nie znaleziono numeru referencyjnego",
                "select_all": "Zaznacz wszystko",
                "all_right": "Wszystko bez błędów",
                "all_errors": "Wszystko wadliwe",
                "empfaenger": "Odbiorca",
                "artikel": "Artykuł",
                "transport": "Transport",
                "error": "Uszkodzony",
                "success": "Bez błędów",
                "edit": "edytować",
                "reset": "Resetowanie",
                "deviation": "Wprowadź odchylenie w liczbie palet i pudeł",
                "ready": "gotowy",
                "number_pallets": "Liczba palet",
                "number_boxes": "Liczba pudełek",
                "delivery_file": "Prosimy o zrobienie zdjęcia otrzymanego dowodu dostawy i dołączenie go",
                "required_file": "Wymagany dowód dostawy",
                "sendet": "wysyłanie",
                "take_image": "Proszę zrobić zdjęcie wadliwego produktu i załączyć je",
                "image_optional": "obraz opcjonalny",
                "error_sendet": "Please upload a confirmed delivery note.",
                "frachtauftrag": "Prześlij potwierdzony dowód dostawy.",
                "close": "Kompletny",
                "done": "Prawie na miejscu",
                "done_txt": "Wszystkie elementy zostały sprawdzone. Prosimy o potwierdzenie przyjęcia.",
                "cancel": "Anuluj"
            }
        },
        ru: {
            translation: {
                "scan_order": "Отсканируйте или введите по порядку",
                "referenznummer": "Справочный номер",
                "search": "поиск",
                "nothing_found": "Номер ссылки не найден",
                "select_all": "выбрать все",
                "all_right": "Все без ошибок",
                "all_errors": "Все неисправно",
                "empfaenger": "Получатель",
                "artikel": "Статья",
                "transport": "Транспорт",
                "error": "Неисправный",
                "success": "Без ошибок",
                "edit": "редактировать",
                "reset": "перезагрузить",
                "deviation": "Введите отклонение в количестве поддонов и коробок",
                "ready": "готовый",
                "number_pallets": "Количество поддонов",
                "number_boxes": "Number of boxes",
                "delivery_file": "Пожалуйста, сфотографируйте полученную накладную и прикрепите ее.",
                "required_file": "Требуется накладная",
                "sendet": "отправка",
                "take_image": "Пожалуйста, сфотографируйте неисправный товар и прикрепите его.",
                "image_optional": "изображение необязательно",
                "error_sendet": "Пожалуйста, загрузите подтвержденную накладную.",
                "frachtauftrag": "Заказ перевозки",
                "close": "Полный",
                "done": "Почти у цели",
                "done_txt": "Все товары были очищены. Пожалуйста, подтвердите принятие.",
                "cancel": "Отмена"
            }
        },
        de: {
            translation: {
                "scan_order": "Scannen oder Auftrag eintippen",
                "referenznummer": "Referenznummer",
                "search": "Suchen",
                "nothing_found": "Referenznummer nicht gefunden",
                "select_all": "Alle auswählen",
                "all_right": "Alle fehlerfrei",
                "all_errors": "Alle fehlerhaft",
                "empfaenger": "Empfänger",
                "artikel": "Artikel",
                "transport": "Transport",
                "error": "Fehlerhaft",
                "success": "Fehlerfrei",
                "edit": "bearbeiten",
                "reset": "Zurücksetzen",
                "deviation": "Abweichung Anzahl Paletten und Kartons eintragen",
                "ready": "Fertig",
                "number_pallets": "Anzahl Paletten",
                "number_boxes": "Anzahl Kartons",
                "delivery_file": "Bitte den quittierten Lieferschein fotografieren und anhängen",
                "required_file": "Lieferschein erforderlich",
                "sendet": "wird gesendet",
                "take_image": "Bitte Bild der fehlerhaften Ware fotografieren und anhängen",
                "image_optional": "Bild optional",
                "error_sendet": "Bitte lade ein quittierten Lieferschein hoch.",
                "frachtauftrag": "Frachtauftrag",
                "close": "Abschließen",
                "done": "Fast geschafft",
                "done_txt": "Alle Artikel wurden bearbeitet. Bitte bestätige die Abnahme.",
                "cancel": "Abbrechen"
            }
        }
    },
    lng: findlang, // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
        escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
});
ReactDOM.render((
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
        <App />
        </I18nextProvider>
    </BrowserRouter>
), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
