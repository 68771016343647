import React from 'react';
import { withRouter } from 'react-router';

class Navbar extends React.Component {

    state = {
        isMenuVisible: false
    };

    constructor(props) {
        super(props);
        this.showCo2 = this.showCo2.bind(this);
        this.showImportList = this.showImportList.bind(this);
        this.showChat = this.showChat.bind(this);
        this.showProfile = this.showProfile.bind(this);
        this.showAdd = this.showAdd.bind(this);
    }

    currentPageIsAdd() {
        return this.props.history.location.pathname.includes("add");
    }

    currentPageIsCo2() {
        return this.props.history.location.pathname.includes("co2");
    }

    currentPageIsImportlist() {
        return this.props.history.location.pathname === "/" || this.props.history.location.pathname.includes("importlist");
    }
    currentPageIsChat() {
        return this.props.history.location.pathname.includes("chat");
    }

    currentPageIsProfile() {
        return this.props.history.location.pathname.includes("profile");
    }



    setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

        document.location.reload()
    }

    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }



    render() {
        return (
            <div style={{marginLeft: 10, marginTop: 5}}>
                <img src={"/lang/de.png"} onClick={() => this.setCookie("user_lang", "de", 356)} style={{width: 20, cursor: "pointer", marginRight: 5}}/>
                <img src={"/lang/en.png"} onClick={() => this.setCookie("user_lang", "en", 356)}  style={{width: 20, cursor: "pointer", marginRight: 5}}/>
                <img src={"/lang/pl.png"} onClick={() => this.setCookie("user_lang", "pl", 356)}  style={{width: 20, cursor: "pointer", marginRight: 5}}/>
                <img src={"/lang/ru.png"} onClick={() => this.setCookie("user_lang", "ru", 356)}  style={{width: 20, cursor: "pointer", marginRight: 5}}/>
            </div>
        )
        return (
            <div className="navbar">
                <div className="navbar-menu-container">
                    <div className="navbar-menu-item" id={ this.currentPageIsCo2() ? "selected": null }
                         style={{ backgroundImage: "url('/co2.svg')" }}
                         onClick={ this.showCo2 } />

                    <div className="navbar-menu-item" id={ this.currentPageIsImportlist() ? "selected": null }
                         style={{ backgroundImage: "url('/importlist.svg')" }}
                         onClick={ this.showImportList } />

                    <div className="navbar-menu-item" id={ this.currentPageIsChat() ? "selected": null }
                         style={{ backgroundImage: "url('/comments-regular.svg')", backgroundSize: "28px" }}
                         onClick={ this.showChat } />

                    <div className="navbar-menu-item" id={ this.currentPageIsAdd() ? "selected": null }
                         style={{ backgroundImage: "url('/add_plus.svg')" }}
                         onClick={ this.showAdd } />

                    <div className="navbar-menu-item" id={ this.currentPageIsProfile() ? "selected": null }
                         style={{ backgroundImage: "url('/user-regular.svg')", backgroundSize: "20px" }}
                         onClick={ this.showProfile } />
                </div>
            </div>
        );
    }

    showImportList() {
        this.props.history.push('/');
    }

    showCo2() {
        this.props.history.push('/co2');
    }

    showAdd() {
        this.props.history.push('/add');
    }

    showChat() {
        this.props.history.push('/chat');
    }

    showProfile() {
        this.props.history.push('/profile');
    }
}

export default withRouter(Navbar);
