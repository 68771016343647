import React from 'react';
import axios from "axios";
import {BASE_URL, SHOW_ENDPOINT} from "../Constants";
import ErrorBox from "../ErrorBox";
import Button from "../Button";
import Modal from 'react-modal';
import LoadingSpinner from "../LoadingSpinner";
import {withTranslation} from "react-i18next";

class Index extends React.Component {
    fileInput = null;
    fileInput2 = null;
    constructor(props) {
        super(props);

        this.state = {
            email: "",
            errors: [],
            errorMessage: null,
            isSent: false,
            isSending: true,
            content: null,
            modalIsOpen: false,
            modalIsDone: false,
            modalItem: null,
            uploadImage: false,
            uploadImage2: false,
            files: [],
            files2: [],
            palette: "",
            carton: "",
            error: null,
            fullstatus: null,
            all_selected: null,
            modalAllIsOpen: false,
            edit: false,
            isSubmitting: false
        };

        this.ok = this.ok.bind(this);
        this.notok = this.notok.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.closeAllModal = this.closeAllModal.bind(this);
        this.submitData = this.submitData.bind(this);
        this.upload = this.upload.bind(this);
        this.closeModalDone = this.closeModalDone.bind(this);
    }

    componentDidMount() {
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id)
        .then(result => {
            let founds = result["data"]["orders"]["items"].length;
            let allerror = result["data"]["orders"]["items"].length == result["data"]["orders"]["items"].filter(y => y.status == "0").length;
            let allcorrect = result["data"]["orders"]["items"].length == result["data"]["orders"]["items"].filter(y => y.status == "1").length;

            let fullstatus = null;
            if(allerror) {
                fullstatus = "0";
            }

            if(allcorrect) {
                fullstatus = "1";
            }

            let all = result["data"]["orders"];


            let status = false;

            //alert(JSON.stringify(all.content["items"].filter(y => y.status == null)));

            if(all["items"].filter(y => y.status == null).length == 0) {
                status = true;
            }

            this.setState({fullstatus: fullstatus, modalIsDone: status, content: all, isSending: false});
        })
        .catch(error => {
            console.log("asdasds111",error)

            window.location = "/"
            if (error.response) {
                this.setState({ isSending: false, errorMessage: this.props.t('nothing_found') });
            }
        });
    }

    ErrorsView() {
        const { errors } = this.state;
        const { errorMessage } = this.state;

        return (
            errorMessage !== null ?
                <ErrorBox errorMessage={ errorMessage } errors={ errors } />
                : null
        );
    }

    ok(item) {
        item.status = "1";
        let found = this.state.content.items.filter(x => x.id == item.id)[0];
        found.status = "1";
        this.submitData(item);
        let allerror =  this.state.content["items"].length ==  this.state.content["items"].filter(y => y.status == "0").length;
        let allcorrect =  this.state.content["items"].length ==  this.state.content["items"].filter(y => y.status == "1").length;

        let fullstatus = null;
        if(allerror) {
            fullstatus = "0";
        }

        if(allcorrect) {
            fullstatus = "1";
        }

        this.setState({fullstatus: fullstatus, content: this.state.content})
    }

    notok(item) {
        item.status = "0"
        let found = this.state.content.items.filter(x => x.id == item.id)[0];
        found.status = "0";

        let allerror =  this.state.content["items"].length ==  this.state.content["items"].filter(y => y.status == "0").length;
        let allcorrect =  this.state.content["items"].length ==  this.state.content["items"].filter(y => y.status == "1").length;

        let fullstatus = null;
        if(allerror) {
            fullstatus = "0";
        }

        if(allcorrect) {
            fullstatus = "1";
        }

        this.setState({files: [], files2: [], fullstatus: fullstatus, modalIsOpen: true, modalItem: item,content: this.state.content})
    }

    submitData(item) {
        this.setState({isSubmitting: true});
        if(this.state.palette != "" && this.state.palette != null) {this.changeCounter();}
        axios.post(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id, item).then(x => {
            let status = false;
            if(this.state.content["items"].filter(y => y.status == null).length == 0) {
                status = true;
            }
            this.setState({isSubmitting: false, modalIsDone: status});
        }).catch(x => this.setState({isSubmitting: false}));
    }

    closeModal() {
        if(this.state.modalItem.selectimg == "1" && this.state.files.length == 0) {
            alert(this.props.t('error_sendet'));
        }else {
            this.submitData(this.state.modalItem);
            let found = this.state.content.items.find(x => x.id == this.state.modalItem.id);
            found.couter_pallets = this.state.palette;
            found.couter_boxes = this.state.carton;

            this.setState({errors: [], files: [], files2: [],errorMessage: null, modalItem: null, palette: "", carton: "", error: null, fullstatus: null,   modalAllIsOpen: false, edit: false, modalIsOpen: false});
        }
    }

    closeAllModal() {
        this.setState({errors: [], files: [], files2: [],errorMessage: null, modalItem: null, palette: "", carton: "", error: null, fullstatus: null, modalAllIsOpen: false, edit: false, modalIsOpen: false});
    }

    closeModalDone() {
        this.setState({modalIsDone: false});
    }

    handOver(art) {
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/hand_over/"+art.toString(), {
                headers: {
                    'Accept': 'application/json',
                    'ContentType': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            })
    }

    selectAllError(art) {
        this.setState({error: art});
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/all/error/"+art.toString(), {
            headers: {
                'Accept': 'application/json',
                'ContentType': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    selectError(art) {
        this.setState({error: art});
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/error/"+art.toString(), {
            headers: {
                'Accept': 'application/json',
                'ContentType': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
    }


    upload(e) {
        let file = e.target.files[0];

        this.state.files.push(e.target.files[0]);

        this.setState({uploadImage: true});
        let formData1 = new FormData();
        formData1.append('image', file );
        formData1.append('type', "note");
        axios.post(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/upload",
            formData1
            , {
                headers: {
                    'Accept': 'application/json',
                    'ContentType': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
            this.setState({files: this.state.files, uploadImage: false});
        }).catch(err => {
            this.setState({uploadImage: false});
            alert("Fehler beim Upload");
        });

        this.fileInput.value = "";
    }

    upload2(e) {
        let file = e.target.files[0];

        this.state.files2.push(e.target.files[0]);

        this.setState({uploadImage2: true});
        let formData1 = new FormData();
        formData1.append('image', file );
        formData1.append('type', "error" );

        axios.post(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/upload",
            formData1
            , {
                headers: {
                    'Accept': 'application/json',
                    'ContentType': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
            this.setState({files2: this.state.files2, uploadImage2: false});
        }).catch(err => {
            this.setState({uploadImage2: false});
            alert("Fehler beim Upload");
        });

        this.fileInput2.value = "";
    }

    reset() {
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/reset",{
            headers: {
                'Accept': 'application/json',
                'ContentType': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        }).then(x => window.location.reload())
    }


    done() {
        axios.get(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/done",{
            headers: {
                'Accept': 'application/json',
                'ContentType': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        }).then(y => window.location = "/")

    }


    changeCounter() {
        let formData1 = new FormData();
        formData1.append('palette', this.state.palette );
        formData1.append('carton', this.state.carton );

        axios.post(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/"+this.state.modalItem.id+"/lost", formData1,{
            headers: {
                'Accept': 'application/json',
                'ContentType': 'multipart/form-data',
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    selectOne(item) {
        if(item.checked) {
            item.checked = false
        }else {
            item.checked = true
        }

        let found = this.state.content.items.filter(x => x.id == item.id)[0];
        found.checked = item.checked;

        this.setState({content: this.state.content})
    }

    selectAll() {
        let all_selected = null
        if(this.state.all_selected == null || !this.state.all_selected) {
            all_selected = true;
        }

        if(this.state.all_selected) {
            all_selected = false;
        }

        this.state.content.items.map(x => {
            x.checked = all_selected;
        });

        this.setState({ all_selected: all_selected, content: this.state.content})
    }

    all(art) {
        let formData1 = new FormData();
        formData1.append('data', JSON.stringify(this.state.content.items.filter(x => x.checked === true)) );
        formData1.append('art', art );

        axios.post(BASE_URL + SHOW_ENDPOINT+this.props.match.params.id+"/all",
            formData1
            , {
                headers: {
                    'Accept': 'application/json',
                    'ContentType': 'multipart/form-data',
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
            if(art != "nook") {
                window.location.reload();
            }else{
                this.changeCounter()
            }
        }).catch(err => {

        });

        if(art == "nook") {
            this.setState({modalAllIsOpen: true})
        }
    }

    Form() {
        return (
            <div>
                <div style={{display: "flex", borderBottom: "1px solid #000", marginBottom: 20}}>

                    {
                        this.state.content.items.filter(x => x.status === null).length != 0 ?
                            <p style={{width: "38%", marginRight: "2%", display: "flex"}}>
                                <input onClick={() => this.selectAll()} type={"checkbox"} name={"selected"} id={"all"}/> <label for={"all"}>{this.props.t('select_all')}</label>
                            </p>
                        :null
                    }

                    {
                        this.state.content.items.filter(x => x.checked === true).length > 0 ?  <Button title={this.props.t('all_right')}
                                                                                              style={{height: 38, fontSize: 10, width: "30%", backgroundColor: 'darkgreen'}}
                                                                                              onClick={ () => this.all("ok") }
                        /> : null
                    }

                    {
                        this.state.content.items.filter(x => x.checked === true).length > 0 ?
                            <Button title={this.props.t('all_errors')}
                            style={{maxWidth: window.outerWidth < 900 ?  "100%" : 200, height: 38, fontSize: 10, width: "30%", backgroundColor: 'darkred', marginLeft: 20}}
                            onClick={ () => this.all("nook") }
                        /> : null
                    }

                </div>
                {this.state.content.items.map(x => <div style={{display:"flex", padding: 10, backgroundColor: x.status == null ? "#fff" : x.status == "1" ? "darkgreen" : "darkred" ,marginBottom: 40}}>
                    {x.status == null ? <div><input onClick={() => this.selectOne(x)} type={"checkbox"} name={"selected"} checked={x.checked}/></div> : null}
                    <div style={{width: "100%"}}>
                        <div>
                            <div style={{color: x.status == null ? "#000":"#fff"}}>{this.props.t('empfaenger')}: {x.recipient}</div>
                            <div style={{color: x.status == null ? "#000":"#fff"}}>{this.props.t('artikel')}: {x.name}</div>
                            <div style={{color: x.status == null ? "#000":"#fff"}}>{this.props.t('transport')}: {x.articel_number} / {x.transport_number}</div>
                        </div>
                        {x.status == null ? <div style={{display: "flex"}}>
                            <Button title={this.props.t('success')}
                                    style={{maxWidth: window.outerWidth < 900 ?  "100%" : 200, backgroundColor: 'darkgreen'}}
                                    onClick={ () => this.ok(x) }
                            />
                            <Button title={this.props.t('error')}
                                    style={{maxWidth: window.outerWidth < 900 ?  "100%" : 200, backgroundColor: 'darkred', marginLeft: 20}}
                                    onClick={ () => this.notok(x) }
                            />
                        </div> : null}
                    </div>
                    {x.status == 0 ? <div style={{marginLeft: "auto", marginRight: 0}}>
                        <Button title={this.props.t('edit')}
                                style={{maxWidth: window.outerWidth < 900 ?  "100%" : 200, backgroundColor: 'black', height: 40, fontSize: 12}}
                                onClick={ () => {this.setState({palette: x.couter_pallets, carton: x.couter_boxes, error: x.error, edit: true, modalIsOpen: true, modalItem: x})} }
                        />
                    </div> : null}

                    {x.status == 1 ? <div style={{marginLeft: "auto", marginRight: 0}}><Button title={this.props.t('reset')}
                                               style={{backgroundColor: 'darkorange', height: 40, fontSize: 12}}
                                               onClick={ () => {this.setState({modalItem: x}, function(){this.reset()})} }
                    /> </div>: null}

                </div>)}

                <div style={{display: "flex", borderTop: "1px solid #000", marginTop: 20}}>
                    {
                        this.state.content.items.filter(x => x.status === null).length != 0 ?
                            <p style={{width: "38%", marginRight: "2%", display: "flex"}}>
                                <input onClick={() => this.selectAll()} type={"checkbox"} name={"selected"} id={"all"}/> <label for={"all"}>{this.props.t('select_all')}</label>
                            </p>
                            :null
                    }

                    {
                        this.state.content.items.filter(x => x.checked === true).length > 0 ?  <Button title={this.props.t('all_right')}
                                                                                                       style={{height: 38, fontSize: 10, width: "30%", backgroundColor: 'darkgreen'}}
                                                                                                       onClick={ () => this.all("ok") }
                        /> : null
                    }

                    {
                        this.state.content.items.filter(x => x.checked === true).length > 0 ?
                            <Button title={this.props.t('all_errors')}
                                    style={{height: 38, fontSize: 10, width: "30%", backgroundColor: 'darkred', marginLeft: 20}}
                                    onClick={ () => this.all("nook") }
                            /> : null
                    }

                </div>

                <Modal
                    isOpen={this.state.modalAllIsOpen}
                    onRequestClose={this.closeAllModal}
                    style={{
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    contentLabel="Example Modal"
                >
                    <button onClick={this.closeAllModal}>x</button>

                    <p style={{display: "none"}}>
                        {this.props.t('deviation')}
                    </p>
                    <div style={{display: "none"}}>
                        <div style={{display: "flex"}}>
                            <input type={"radio"} checked={this.state.error == "wb"} onClick={() => this.selectAllError("wb")} name={"error"} value="wb"
                                   id={"av"}/>
                            <label htmlFor={"av"} onClick={() => this.selectAllError("wb")}>Ware beschätigt</label>
                        </div>
                        <div style={{display: "flex", marginLeft: 20}}>
                            <input type={"radio"} checked={this.state.error == "fw"} onClick={() => this.selectAllError("fw")} name={"error"} value="fw"
                                   id={"mm"}/>
                            <label htmlFor={"mm"} onClick={() => this.selectAllError("fw")}>Falsche Ware</label>
                        </div>

                        <div style={{display: "flex", marginLeft: 20}}>
                            <input type={"radio"} checked={this.state.error == "fl"} onClick={() => this.selectAllError("fl")} name={"error"} value="fl"
                                   id={"br"}/>
                            <label htmlFor={"br"} onClick={() => this.selectAllError("fl")}>Falscher Liefertermin</label>
                        </div>
                    </div>


                    <Button title={this.props.t('ready')}
                            style={{backgroundColor: 'darkgreen'}}
                            onClick={ () => window.location.reload() }
                    />
                </Modal>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={{
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    contentLabel="Example Modal"
                >

                    <p>
                        {this.props.t('deviation')}
                    </p>
                    <div style={{display: "none"}}>
                        <div style={{display: "flex"}}>
                            <input type={"radio"} checked={this.state.error == "av"} onClick={() => this.selectError("av")} name={"error"} value="av"
                                   id={"av"}/>
                            <label htmlFor={"av"} onClick={() => this.selectError("av")}>Annahmeverweigerung</label>
                        </div>
                        <div style={{display: "flex", marginLeft: 20}}>
                            <input type={"radio"} checked={this.state.error == "mm"} onClick={() => this.selectError("mm")} name={"error"} value="mm"
                                   id={"mm"}/>
                            <label htmlFor={"mm"} onClick={() => this.selectError("mm")}>Mindermenge</label>
                        </div>

                        <div style={{display: "flex", marginLeft: 20}}>
                            <input type={"radio"} checked={this.state.error == "br"} onClick={() => this.selectError("br")} name={"error"} value="br"
                                   id={"br"}/>
                            <label htmlFor={"br"} onClick={() => this.selectError("br")}>Bruch</label>
                        </div>
                    </div>

                  <div>
                        <div style={{marginTop: 20}}>
                            <span>{this.props.t('number_pallets')}</span>
                            <input type="text" placeholder={this.props.t('number_pallets')} value={ this.state.palette } onChange={ (e) => this.setState({palette: e.target.value}, function() {}) } />
                        </div>
                        {
                            this.state.palette != "" ? <div>
                            <span>{this.props.t('number_boxes')}</span>
                            <input type="text" placeholder={this.props.t('number_boxes')} value={ this.state.carton } onChange={ (e) => this.setState({carton: e.target.value}, function() {}) } />
                        </div>
                            : null
                        }
                    </div>

                    <p style={{marginTop: 10, marginBottom: 20}}>{this.props.t('delivery_file')} / <span style={{textDecoration: "unuderline", fontWeight: "600"}}>{this.props.t('required_file')}</span></p>
                    {this.state.files.length == 0 ? null : this.state.files.map(x => {
                        if(x.name.indexOf("pdf") != -1){
                            return <img src={"/pdf.png"} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                        }
                        return <img src={URL.createObjectURL(x)} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>
                    })}
                    {this.state.modalItem == null ? null : this.state.modalItem.images.filter(yy => yy.url.indexOf("-note-") != -1).length == 0 ? null : this.state.modalItem.images.filter(yy => yy.url.indexOf("-note-") != -1).map(x => {
                        if(x.filename.indexOf("pdf") != -1){
                            return <img src={"/pdf.png"} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                        }
                        return <img src={x.url} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                    })}
                    <div>{this.state.uploadImage ? <LoadingSpinner title={this.props.t('sended')}/> : <input type={"file"} ref={ref => this.fileInput = ref} onChange={(e) => this.upload(e)} style={{backgroundColor: "transparent"}}/>}</div>

                    <p>{this.props.t('take_image')} / <span style={{textDecoration: "unuderline", fontWeight: "600"}}>{this.props.t('image_optional')}</span></p>
                    {this.state.files2.length == 0 ? null : this.state.files2.map(x => {
                        if(x.name.indexOf("pdf") != -1){
                            return <img src={"/pdf.png"} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                        }
                        return <img src={URL.createObjectURL(x)} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>
                    })}
                    {this.state.modalItem == null ? null : this.state.modalItem.images.filter(yy => yy.url.indexOf("-break-") != -1).length == 0 ? null : this.state.modalItem.images.filter(yy => yy.url.indexOf("-break-") != -1).map(x => {
                        if(x.filename.indexOf("pdf") != -1){
                            return <img src={"/pdf.png"} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                        }
                        return <img src={x.url} style={{width: 100, marginBottom: 15, marginRight: 5, borderRadius: 10  }}/>;
                    })}
                    <div style={{marginBottom: 10}}>{this.state.uploadImage2 ? <LoadingSpinner title={this.props.t('sendet')}/> : <input type={"file"} ref={ref => this.fileInput2 = ref} onChange={(e) => this.upload2(e)} style={{backgroundColor: "transparent"}}/>}</div>

                    {this.state.isSubmitting ? <LoadingSpinner title={this.props.t('sendet')}/> : this.state.uploadImage || this.state.uploadImage2 ? null : <div>
                        <Button title="Fertig"
                                style={{backgroundColor: 'darkgreen'}}
                                onClick={ () => this.closeModal() }
                        />
                        {this.state.edit ? <Button title="Zurücksetzen"
                                                   style={{backgroundColor: 'darkorange'}}
                                                   onClick={ () => this.reset() }
                        /> : null}
                    </div>}

                </Modal>

                <Modal
                    isOpen={this.state.modalIsDone}
                    onRequestClose={this.closeModalDone}
                    style={{
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                    contentLabel="Example Modal"
                >
                    <div>
                        <h2>
                            {this.props.t('done')}
                        </h2>
                        <p>
                            {this.props.t('done_txt')}
                        </p>
                        <div style={{display: "flex", flexDirection: "row"}}>
                            <Button title={this.props.t('cancel')}
                                    style={{flex: 1, marginRight: 5,backgroundColor: 'darkred'}}
                                    onClick={ () => this.closeModalDone() }
                            />
                            <Button title={this.props.t('close')}
                                    style={{flex: 1, marginLeft: 5, backgroundColor: 'darkgreen'}}
                                    onClick={ () => this.done() }
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    colored() {
        if(this.state.fullstatus == "1") {
            return "darkgreen";
        }

        if(this.state.fullstatus == "0") {
            return "darkred";
        }

        return "#000";
    }

    render() {
        return (this.state.isSending ? null : <div className="wrapper">
                <div className="titlebar" style={{height: "auto"}}>
                    <div>
                        <div>
                            <a href={"/"}><img src={'/logo.png'} style={{ width: 100}}/></a>
                        </div>
                    </div>
                    <div style={{display: window.outerWidth < 900 ? "block" : "flex"}}>
                        <p className="titlebar-title" style={{lineHeight: 2}}>{this.props.t('frachtauftrag')} <span style={{color: this.colored()}}>{this.state.content.transport_nr}</span></p>
                        {
                            this.state.content["items"].filter(y => y.status == null).length == 0 ? <div style={window.outerWidth < 900 ? {textAlign: "center"} : {marginLeft: "auto", marginRight: 0, marginTop: window.outerWidth < 900 ? 0 : 10}}>
                                <Button title={this.props.t('close')}
                                        style={{height: 38, fontSize: 10,maxWidth: window.outerWidth < 900 ?  "100%" : 200, backgroundColor: 'darkgreen'}}
                                        onClick={ () => this.done() }
                                />
                            </div>: null
                        }
                    </div>
                </div>

                <div className="content" style={{marginTop: 10}}>
                    {
                        this.Form()
                    }
                </div>
                <div className="titlebar" style={{height: "auto"}}>
                    <div>

                    </div>
                    <div style={{display: window.outerWidth < 900 ? "block" : "flex"}}>

                        {
                            this.state.content["items"].filter(y => y.status == null).length == 0 ? <div style={window.outerWidth < 900 ? {textAlign: "center"} : {marginLeft: "auto", marginRight: 0, marginTop: window.outerWidth < 900 ? 0 : 10}}>
                                <Button title={this.props.t('close')}
                                        style={{height: 38, fontSize: 10, maxWidth: window.outerWidth < 900 ?  "100%" : 200, backgroundColor: 'darkgreen'}}
                                        onClick={ () => this.done() }
                                />
                            </div>: null
                        }
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Index);
